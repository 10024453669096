import { Container } from "react-bootstrap";

function AccountRoute() {
  return  (
    <Container>
      Account
  </Container>




  );
}

export {AccountRoute};