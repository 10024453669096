import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import { BillStatus } from './billStatus';

import './bills.css'


export const BillingPeriod = ({period}) => {
    return (
      // <Tab.Pane className="m-3" key={key} eventKey={period.id}>
      <Tab.Pane className="m-3" eventKey={period.id}>
        {period.period}
        <Container>
        <Row>
        {
          period.bills.map((bill, i)=>{
            
            return (
            <Col xs={6} key={i}>
              <div className={`bill-box rounded-3 border b-item bg-secondary`}>
            {bill.name} {bill.amount} {bill.currency}
            <div>
              {bill.status} 
            <BillStatus bill={bill} />
              </div>
             
              </div>
            </Col>
          )})
        }
        </Row>
      </Container>
      </Tab.Pane>
    )
  }
  