import { useQuery } from 'react-query'
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import { getBills } from '../../services/bills';
import {BillingPeriod} from './billingPeriod'

import './bills.css'

export function BillsRoute(props) {
  const fetchBills = () => getBills({key:123})
  const { isLoading, isError, data:billsData, error } = useQuery('bills', fetchBills, {retry:false})


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    // console.log(error)
    return <div>Error: {error.status}</div>;
  }

  const renderBillingUnitTabs = () => {
    return billsData.map((unitData, ti)=>{
      let defaultPeriodId = unitData.periods.length ? unitData.periods[0].id : ''
      return (<Tab eventKey={unitData.id} title={unitData.name} key={ti}>
        <Tab.Container id="left-tabs-example" defaultActiveKey={defaultPeriodId}>
      <Row>
        <Col className="d-flex">
          <Nav variant="pills" className="flex-column">
            {
              unitData.periods.map((period, i)=>{
                return (
                <Nav.Item key={i}>
                  <Nav.Link eventKey={period.id}>{period.period}<div className='periodStatusClean'></div> </Nav.Link>
                </Nav.Item>)
              })
            }
          </Nav>
          <Tab.Content>
            {
              unitData.periods.map((period,i)=><BillingPeriod period={period} key={i} /> )
            }
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
      </Tab>)
    })
  }

  let defaultTabId = billsData.length ? billsData[0].id : ''
  return  (
    <div className='container-sm justify-content-center'>

    <Tabs
      id="uncontrolled-tab-example"
      className="mb-3"
      defaultActiveKey={defaultTabId}
      >
      {renderBillingUnitTabs()}
      
      
    </Tabs>        
    <div>
    RACUNI 
        - baca na trnutni mesec
        - levi meni da prikaze prethodne mesece
        - gornji meni da prikazuje jedinice/vlahovic/nbg
        - na glavnom contentu da se vide ikonice stavki za mesec
            - koje se expanduju
                - datum placanja
                - iznos
                - prepaid/postpaid
                - komentar
                - status placen/neplacen
        - [buduci meseci da se vide takodje]
    </div>
    </div>
  );
}
