import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { checkToken } from "../services/auth"
import { PageNotAllowed } from "./page-not-allowed"

function ProtectedRoutes(props) {
  const currentPath=useLocation().pathname;
  const token = checkToken(props.state.setAuth)
  var permissionAllowed = true;

  if (Boolean(props.permissions)){
    props.permissions.forEach(permission => {
      if (token && !token.permissions.includes(permission)){
        permissionAllowed = false;
      }
    });
    
  }
  if (!permissionAllowed) {
    return <PageNotAllowed path={currentPath} />
  }

    const user=Boolean(props.state.auth);
    const isLoginPage=Boolean('/login'===currentPath);
    const loginPath=`/login?redirect=${currentPath}`;
    const loggedUserLoginPage = user && isLoginPage
    const notLoggedUserNotLoginPage = !user && !isLoginPage

    return (
      loggedUserLoginPage ? <Navigate to="/" /> : notLoggedUserNotLoginPage ? <Navigate to={loginPath} /> : <Outlet /> 
  
    );
  }

export { ProtectedRoutes };