import React from 'react';
import {Dropdown} from 'react-bootstrap'

import { Link } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { logOut } from '../services/auth';
import { t } from "../common/content";
import { RefWrapper } from "../common/utils";

const AccountMenu = (props) => {
  let user = props.state.auth
return (

  <Dropdown>
<Dropdown.Toggle as={RefWrapper}  size={37} component={MdAccountCircle} className={`btn bg-primary rounded-circle btn-sm`} /> 
{/* <Dropdown.Toggle as={RefWrapper}  size={30} component={MdAccountCircle} />  */}

<Dropdown.Menu align={{ lg: 'start' }}>

<Dropdown.Header>{user?.username} 
  {
    user?.permissions.map((p) => `, ${p}`)
  }

</Dropdown.Header>
  <Dropdown.Item as={Link} to="/account">{t('Account')}</Dropdown.Item>
  <Dropdown.Item onClick={() => logOut(props.state.setAuth)}>{t('Logout')}</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
)
};





export default AccountMenu;
