
import {translations} from '../assets/translations';
import {DEFAULT_LANG} from '../constants';

export const changeLang = (lang) => {
    let currLang = localStorage.getItem('lang')
    if (currLang !== lang) {
        localStorage.setItem('lang', lang)
        window.location.reload();
    }
}


export const t = (raw_value, interpolationArgs) => {
    var lang = localStorage.getItem('lang')
    if (!Boolean(lang)) {
        lang = DEFAULT_LANG
        localStorage.setItem('lang', lang)
    }
    var translated_value = raw_value
    
    if (DEFAULT_LANG !== lang){
        //todo translation
        translated_value = translations[lang][raw_value] || '['+raw_value+']'
    }


    if (interpolationArgs===undefined || !Boolean(interpolationArgs)){
        return translated_value
    }
    else{
        
        for (const [key, value] of Object.entries(interpolationArgs)) {
            // console.log(`${key}: ${value}`, `{{${key}}}`);
            translated_value = translated_value.replace(`{{${key}}}`, value)
            // debugger
        }
    }

    return translated_value



  }