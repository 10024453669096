import { t } from "../common/content"
import { useState } from "react";
import AccountMenu from "./account_menu";
import { APP_NAME } from "../constants";
import Container from 'react-bootstrap/Container';

import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Col, Row } from "react-bootstrap";
import LangSwitch from "./lang_switch";
import AppsMenu from "./apps_menu";

function Header(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Container>
      <Row>
        <Col md={8}>
          <Navbar expand="md" collapseOnSelect>
            <Container>

              <Navbar.Brand as={Link} to="/">{t(APP_NAME)}</Navbar.Brand>
              <Navbar.Toggle onClick={() => setExpanded(!expanded)} />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link
                    as={Link} to="/"
                    eventKey="login"
                  >
                    {t('Home')
                    }</Nav.Link>
                  <Nav.Link
                    as={Link} to="/account"
                    eventKey="account"
                  >{t('Account')}</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
        <Col md={4} className="p-3 d-flex justify-content-center">
              <div className="p-1"><AppsMenu /></div>
              <div className="p-1"><LangSwitch /></div>
              <div className="p-1">
              {props.state.auth ? (
                <AccountMenu {...props} />
              ) : (
                <Link href="/login">
                  <Button variant="success">{t('Login')}</Button>
                </Link>
              )}
              
              </div>
        </Col>
      </Row>
    </Container>

  );
}

export { Header };