import React from 'react';
import {Dropdown} from 'react-bootstrap'

import { Link } from "react-router-dom";
import { IoApps } from "react-icons/io5";
import { t } from "../common/content";
import { RefWrapper } from "../common/utils";
import { TbInvoice } from "react-icons/tb";

const AppsMenu = (props) => {

return (

  <Dropdown>
<Dropdown.Toggle as={RefWrapper} size={37} component={IoApps} />
<Dropdown.Menu align={{ lg: 'start' }}>
  <Dropdown.Item as={Link} to="/bills"><TbInvoice /> {t('Bills')}</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
)
};





export default AppsMenu;
