// import logo from './logo.svg';
import { useState, useEffect } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import {
  Routes,
  Route
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { ToastContainer } from 'react-toastify';
import { HomeRoute } from "./routes/home/home"
import { AccountRoute } from "./routes/account/account"
import { LoginRoute } from "./routes/login/login"
import { BillsRoute } from "./routes/bills/bills"
import { Header } from "./components/header"
import { Footer } from "./components/footer"
import { PageNotFound } from "./components/page-not-found"
import { t } from "./common/content"
import { permissions } from "./common/permissions"
import { checkToken } from "./services/auth"
import { ProtectedRoutes } from "./components/protectedRoutes"
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from 'react-bootstrap';
import { APP_NAME } from './constants';

function App() {

  const queryClient = new QueryClient();
  const [auth, setAuth] = useState(checkToken());
  var state = {
    'auth': auth,
    'setAuth': setAuth,
  }

  useEffect(() => {
    document.title = t(APP_NAME)
    let authState = checkToken(setAuth);
    setAuth(authState)
  }, []);



  return (
    <QueryClientProvider client={queryClient}>
    <Container>
      <Header state={state} />
      <main className=" container">
        <Routes>

          {/* protected routes auth only */}
          <Route element={<ProtectedRoutes state={state} />}>
            <Route path="login" element={<LoginRoute state={state} />} />
            <Route path="/" element={<HomeRoute state={state} />} />
            <Route path="/account" element={<AccountRoute state={state} />} />
          </Route>

          {/* protected routes with permissions */}
          <Route path="bills" element={<ProtectedRoutes state={state} permissions={[permissions.VIEW_BILLS]} />}>
            <Route index={true} element={<BillsRoute />} />
            {/* <Route path=":purchaseId" element={<OrderDetailsRoute state={state} />} /> */}
          </Route>
          <Route path="*" element={<PageNotFound />} />

        </Routes>
      </main>
      <Footer />
      <ToastContainer />
    </Container>
    </QueryClientProvider>
  );
}

export default App;
