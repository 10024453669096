import React from 'react';
import {Dropdown} from 'react-bootstrap'

import { t, changeLang } from "../common/content"

const LANG_LABELS = {
    'eng': t('English'),
    'srb': t('Serbian'),
}

const LangSwitch = () => {
    let currLang = localStorage.getItem('lang')
    const lang = LANG_LABELS[currLang]
  return     <Dropdown>
  <Dropdown.Toggle variant="primary" id="dropdown-basic">
    {lang}
  </Dropdown.Toggle>

  <Dropdown.Menu align={{ lg: 'start' }}>
    <Dropdown.Item onClick={() => changeLang('srb')}>{LANG_LABELS['srb']}</Dropdown.Item>
    <Dropdown.Item onClick={() => changeLang('eng')}>{LANG_LABELS['eng']}</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
};

export default LangSwitch;
