import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useSearchParams, useNavigate} from 'react-router-dom';
import { logIn } from "../../services/auth"
import {toast} from 'react-toastify';
import { t } from "../../common/content"
import { TENANT_ID } from "../../constants";


function LoginRoute(props) {
  
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();


  const handleLogin = (e) => {
    e.preventDefault()

    let formData = new FormData(e.target);
    let formDataObject = Object.fromEntries(formData.entries());

    logIn(formDataObject, props.state.setAuth, () => {
      let redirectTo = searchParams.get("redirect")
      navigate(redirectTo)
      },
      (err) => {
        let msg = err.generalMessage
        if (err.status === 401) {
          msg = t("Invalid username or password")
        }
        toast.error(msg)
    })
    
  }

  return  (
    <div className='container-sm d-flex justify-content-center'>
    <Form onSubmit={handleLogin} className="p-5">
    <Form.Group className="mb-3" controlId="formBasicEmail">
      {/* <Form.Label>{t("Email")}</Form.Label> */}
      <Form.Control type="email" placeholder={t("Email")} name='username' required />
    </Form.Group>
    <input name="tenantId" value={TENANT_ID} type="hidden" />
    <Form.Group className="mb-3" controlId="formBasicPassword">
      {/* <Form.Label>{t("Password")}</Form.Label> */}
      <Form.Control type="password" placeholder={t("Password")} name='password' required />
    </Form.Group>
    <Button variant="primary" type="submit">
      {t("Login")}
    </Button>
  </Form>
  </div>
  );
}

export {LoginRoute};