import React from 'react';
import { t } from "./content"

export const RefWrapper = React.forwardRef((props, ref) => {
    const {component, ...restProps} = props;
    return (
        <props.component {...restProps} />
    )
});

export const responseWrapper = (status) => {
    switch (status) {
        case 500:
            return t('Service error, please try again later')
        case 401:
            return t("You're not authorised for this action")
        default:
            return "OK"

    }
}