import queryString from 'query-string';
import { TENANT_ID } from '../constants';
import { responseWrapper } from './utils';

export const sendRequest = ({
  url,
  method,
  useCredentials=false,
  addAppHeaders=true,
  body,
  headers = {},
  queryParams = {}
}) => {
  let token = localStorage.getItem('authToken', false);
  let options = {
    method: method,
    headers: addAppHeaders ? new Headers({ 
      'content-type': 'application/json', 
      'tenantid': TENANT_ID, 
      'Authorization': 'Bearer ' + token, 
      ...headers }) : new Headers({ 
          'content-type': 'application/json', 
          ...headers }),

    body: body ? JSON.stringify(body) : null
  };

  if (useCredentials) options.credentials = "include";
  if (queryParams) {
    url = `${url}?${queryString.stringify(queryParams)}`;
  }

  // return fetch(url)
  return fetch(url, options)
  .then(res => {
    if (res.ok) {
      return res.json();
    } else {
      return res.json()
        .then(function(json) {
        // to be able to access error status when you catch the error
        return Promise.reject({
          status: res.status,
          ok: false,
          message: json.msg,
          generalMessage: responseWrapper(res.status),
          body: json
        });
      });
    }
  })
  // .catch((error) => {
  //   console.log(error)
  // })
  ;

  // fetch(url, options).then((response) => {
  //   if (response.ok) {
  //     return response.json();
  //   }
  //   if (response.status >= 400 && response.status < 500){

  //   }
    
  //   throw new Error('Something went wrong');
  // })
  // .then((responseJson) => {
  //   // Do something with the response
  // })
  // .catch((error) => {
  //   console.log(error)
  // });
};
