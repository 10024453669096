import React from 'react';
import {Dropdown} from 'react-bootstrap'

import { FaCheck } from "react-icons/fa";
import { MdOutlineWatchLater } from "react-icons/md";
import { RiFileForbidLine } from "react-icons/ri";
// import { logOut } from '../services/auth';
import { t } from "../../common/content";
import { RefWrapper } from "../../common/utils";

const statuses = {
  NOT_PAID: 1,
  PAID: 2,
}

export const BillStatus = (props) => {
  // let user = props.state.auth
  let component = null;
  let className = 'bg-info';
  switch (props.bill.status) {
    case 'PAID':
      component=FaCheck
      className='bg-success'
      break
      case 'NOT PAID':
        component=MdOutlineWatchLater
        className='bg-warning'
      break
    default:
      component=RiFileForbidLine
  } 
  const setStatus = (statusId) => {

  }
return (

  <Dropdown>
<Dropdown.Toggle as={RefWrapper}  size={30} component={component} className={`btn rounded-circle btn-sm ${className}`}  /> 
<Dropdown.Menu align={{ lg: 'start' }}>

  <Dropdown.Item onClick={() => setStatus(statuses.PAID)}>{t('Paid')}</Dropdown.Item>
  <Dropdown.Item onClick={() => setStatus(statuses.NOT_PAID)}>{t('Not paid')}</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>
)
};


// import React from 'react';
// import {Dropdown} from 'react-bootstrap'
// import { MdAccountCircle } from "react-icons/md";

// import { t, changeLang } from "../../common/content"

// const LANG_LABELS = {
//     'eng': t('English'),
//     'srb': t('Serbian'),
// }

// export const BillStatus = () => {
//   return     <Dropdown>
//   <Dropdown.Toggle className='bill-status' id="dropdown-basic">
//   {/* <Dropdown.Toggle variant="success" id="dropdown-basic"> */}
//   <div className="btn btn-warning rounded-circle btn-sm">
//             <MdAccountCircle  />
//               </div>
//   </Dropdown.Toggle>

//   <Dropdown.Menu className='bill-status' align={{ lg: 'start' }}>
//     <Dropdown.Item className='bill-status' onClick={() => changeLang('srb')}>{LANG_LABELS['srb']}</Dropdown.Item>
//     <Dropdown.Item className='bill-status' onClick={() => changeLang('eng')}>{LANG_LABELS['eng']}</Dropdown.Item>
//   </Dropdown.Menu>
// </Dropdown>
// };

