import { APP_NAME } from "../constants";

function Footer(props) {
  return  (
  <footer>
    <p className="border-top text-center text-muted">© {new Date().getFullYear()} { APP_NAME }</p>
  </footer>

  );
}

export {Footer};