import {sendRequest} from "../common/sendRequest"
import { t } from "../common/content"
import { isExpired, decodeToken } from "react-jwt";
import { API_HOST } from "../constants";


const lsTokenKey = 'authToken'

export async function logIn(credentials, stateSetter, succesCb, failCb) {
    let url = `${API_HOST}/api/v1/auth/authenticate`
    sendRequest({
            url,
            method: 'POST',
            addAppHeaders: true,
            body: credentials
        }).then((token) => {
            if (isExpired(token)){
                failCb(t("Token expired"))
            }
            let decodedToken = decodeToken(token);
            localStorage.setItem('authToken', token)
            stateSetter(decodedToken)
      
    
            succesCb()
          }).catch((error) => {
              failCb(error)

          }
        )
}



export async function logOut(stateSetter) {
    // let url = `${API_HOST}/api/v1/auth/logout`
    // sendRequest({
    //         url,
    //         method: 'POST',
    //         addAppHeaders: false,
    //     }).then((data) => {
            localStorage.removeItem(lsTokenKey)
            stateSetter(false)
        //   })
        //   .catch((error) => {
            
        // })
}

export const getToken = () => {
    return localStorage.getItem(lsTokenKey, false);
}   
export const checkToken = (stateSetter=false) => {
    var token = getToken();
    var claims = false
    if (Boolean(token)){
        if (stateSetter && isExpired(token)){
            logOut(stateSetter)
        }
        else{
            claims = decodeToken(token)
        }
    }
    return claims
}
