import {Container} from "react-bootstrap";
import {t} from "../common/content";

function PageNotFound(props) {
  return  (
  <Container>
    <h2>{ t("404 - Page not found") }</h2>
  </Container>

  );
}

export {PageNotFound};