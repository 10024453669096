import { Container } from "react-bootstrap";
import { TbInvoice } from "react-icons/tb";
import { t } from "../../common/content";

function HomeRoute() {
  return  (
    <Container>
      <TbInvoice size={50} /> {t('Bills')}
  </Container>

  );
}

export {HomeRoute};