import {Container} from "react-bootstrap";
import {t} from "../common/content";

export function PageNotAllowed(props) {
  return  (
  <Container>
    <h2>{ t("403 - You don't have permission to view this page") }</h2>
  </Container>

  );
}
